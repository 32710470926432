import styled from "styled-components";

// search with button
export const SearchWithButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: ${({ height, theme }) => height || theme.input.minHeight};
  width: ${({ width }) => width || "100%"};
  padding: 3px 3px 3px 10px;
  border-radius: ${({ theme }) => theme.input.borderRadius};
  background-color: ${({ bgColor, theme }) =>
    bgColor ? bgColor : theme.input.bgColor.default};
  border: 1px solid
    ${({ borderColor, theme }) =>
      borderColor ? borderColor : theme.input.borderColor.default};
  &:hover {
    border-color: ${({ hoverBorderColor, theme, error }) =>
      !error && (hoverBorderColor || theme.input.borderColor.hover)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
  &:focus-within {
    border-color: ${({ focusBorderColor, theme, error }) =>
      !error && (focusBorderColor || theme.input.borderColor.focus)};
    box-shadow: ${({ theme, error }) => !error && theme.input.focusShadow};
  }
`;
export const SearchWithButtonInput = styled.input`
  width: ${({ width }) => width || "calc(100% - 63px)"};
  background-color: ${({ theme }) => theme.colors.main.white};
  border: none;
  color: ${({ textColor, theme }) => textColor || theme.input.textColor};
  font-size: ${({ fontSize, theme }) => fontSize || theme.input.fontSize};
  ::placeholder,
  ::-webkit-input-placeholder {
    font-size: ${({ fontSize, theme }) =>
      fontSize || theme.input.placeholder.size};
    color: ${({ theme }) => theme.input.placeholder.color};
  }
  :-ms-input-placeholder {
    font-size: ${({ fontSize, theme }) =>
      fontSize || theme.input.placeholder.size};
    color: ${({ theme }) => theme.input.placeholder.color};
  }
  &:focus {
    outline: none;
  }
`;
export const SearchButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ size }) => size || "32px"};
  width: ${({ size }) => size || "32px"};
  background-color: ${({ theme }) => theme.colors.main.primary};
  border-radius: ${({ theme }) => theme.borderRadii.button};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.hover.primary};
  }
`;

export const ClearSearchButton = styled.div`
  height: ${({ size }) => size || "15px"};
  width: ${({ size }) => size || "15px"};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadii.circle};
  background-color: ${({ theme }) => theme.colors.main.grayMedium};
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.hover.grayMedium};
  }
`;

export const SearchWithClearButtonContainer = styled.div`
  position: relative;
  display: flex;
  width: ${({ width }) => width || "300px"};
  max-width: ${({ maxwidth }) => maxwidth || "300px"};
`;

export const SearchWithClearButtonInput = styled.input`
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-position: ${({ backgroundImage }) =>
    backgroundImage ? "8px 9px" : ""};
  background-size: ${({ backgroundImage }) => (backgroundImage ? "20px" : "")};
  background-repeat: ${({ backgroundImage }) =>
    backgroundImage ? "no-repeat" : ""};
  /* max-width: 270px; */
  width: 100%;
  font-size: 13px;
  padding: 12px 30px 12px 40px;
  padding-left: ${({ backgroundImage }) => !backgroundImage && "20px"};
  background-color: ${({ theme }) => theme.colors.main.whiteBg};
  border: 1px solid ${({ theme }) => theme.colors.border.secondaryLight};
  border-radius: ${({ theme }) => theme.input.borderRadius};
  // opacity: 0.7;
  height: 40px;
  &:hover {
    border: 1px solid ${({ theme }) => theme.input.borderColor.hover};
    box-shadow: 0 0 0 4px ${({ theme }) => theme.colors.main.focusShadow};
  }
  &:focus {
    border: 1px solid ${({ theme }) => theme.colors.border.primary};
    box-shadow: 0 0 0 4px ${({ theme }) => theme.colors.main.focusShadow};
    outline: none;
  }
`;
