import { memo } from "react";
import ComponentCircleLoader from "../../../../components/Loaders/ComponentCircleLoader";
import NoDataComponent from "../../../../components/NoDataComponent/NoDataComponent";

export const TimesheetLoading = memo(() => <ComponentCircleLoader />);

export const EmptyTimesheet = memo(() => (
  <NoDataComponent title="No timesheets found." />
));

TimesheetLoading.displayName = "TimesheetLoading";
EmptyTimesheet.displayName = "EmptyTimesheet";
