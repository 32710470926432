import { useState, useEffect, Fragment } from "react";
import moment from "moment";
import {
  CardTitle,
  CommonFlex,
  TableText,
  Container,
  CommonImage,
  RoundMiniImage,
} from "../../styledComponents/common";
import { WhiteButton, ColoredButton } from "../../styledComponents/buttons";
import {
  AssigneesManageImgTextGrid,
  AssigneesManageRoundImage,
} from "../../styledComponents/createProject";
import {
  UpperTableSection,
  CheckBox,
  UpperColorRecognitionSection,
  UpperColorRecognition,
  ColoredBar,
  ButtonContainer,
  PayrollInfoTable,
  CheckBoxTextContainer,
  PaymentButtonContainer,
  RequireApprovalDiv,
  RequireTooltipContainer,
} from "../../styledComponents/payroll";
import { TableItem } from "../../styledComponents/teams";
import { toHHMMSS } from "../../utils/helper";
import { currencySymbolMap } from "../../utils/currencies";

import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import CommonTooltip from "../../components/Tooltip/CommonTooltip";

import { PayrollSummaryPayables } from "./PayrollSummary";

import ConfirmPaymentModal from "./ConfirmPaymentModal";

import avatar1 from "../../assets/img/avatar_1.svg";
import avatar2 from "../../assets/img/avatar_2.svg";
import avatar3 from "../../assets/img/avatar_3.svg";
import avatar4 from "../../assets/img/avatar_4.svg";
import Tick from "../../assets/img/icons/tick.svg";
import TickWhite from "../../assets/img/icons/tick_2.svg";
import tickWhiteBold from "../../assets/img/icons/tick-white-bold.svg";
import FirstRoundLetterComp from "../../components/FirstRoundLetter/FirstRoundLetter";

const payrollInfoList = [
  {
    id: 1,
    date: "Wed, July 20, 2020",
    memberList: [
      {
        id: 1,
        name: "Jhon Smith",
        avatar: avatar1,
        timeWorked: "04:42:27",
        payRate: 10.0,
        totalPay: 120.0,
        billRate: null,
        totalBill: null,
        markAsPaid: false,
        sendPayment: false,
        selectMember: 0,
      },
      {
        id: 2,
        name: "John Doe",
        avatar: avatar2,
        timeWorked: "03:23:49",
        payRate: 18.0,
        totalPay: 245.0,
        billRate: null,
        totalBill: null,
        markAsPaid: false,
        sendPayment: false,
        selectMember: 0,
      },
      {
        id: 3,
        name: "Kevin Peter",
        avatar: avatar3,
        timeWorked: "04:42:27",
        payRate: 22.0,
        totalPay: 320.0,
        billRate: null,
        totalBill: null,
        markAsPaid: false,
        sendPayment: false,
        selectMember: 0,
      },
      {
        id: 4,
        name: "Walter Samual",
        avatar: avatar4,
        timeWorked: "03:23:49",
        payRate: 15.0,
        totalPay: 185.0,
        billRate: null,
        totalBill: null,
        markAsPaid: false,
        sendPayment: false,
        selectMember: 0,
      },
    ],
    selectAllmembers: 0,
  },
  {
    id: 2,
    date: "Wed, July 19, 2020",
    memberList: [
      {
        id: 1,
        name: "Jhon Smith",
        avatar: avatar1,
        timeWorked: "04:42:27",
        payRate: 10.0,
        totalPay: 120.0,
        billRate: null,
        totalBill: null,
        markAsPaid: false,
        sendPayment: false,
        selectMember: 0,
      },
      {
        id: 2,
        name: "John Doe",
        avatar: avatar2,
        timeWorked: "03:23:49",
        payRate: 18.0,
        totalPay: 245.0,
        billRate: null,
        totalBill: null,
        markAsPaid: false,
        sendPayment: false,
        selectMember: 0,
      },
      {
        id: 3,
        name: "Kevin Peter",
        avatar: avatar3,
        timeWorked: "04:42:27",
        payRate: 22.0,
        totalPay: 320.0,
        billRate: null,
        totalBill: null,
        markAsPaid: false,
        sendPayment: false,
        selectMember: 0,
      },
      {
        id: 4,
        name: "Walter Samual",
        avatar: avatar4,
        timeWorked: "03:23:49",
        payRate: 15.0,
        totalPay: 185.0,
        billRate: null,
        totalBill: null,
        markAsPaid: false,
        sendPayment: false,
        selectMember: 0,
      },
    ],
    selectAllmembers: 0,
  },
  {
    id: 3,
    date: "Wed, July 18, 2020",
    memberList: [
      {
        id: 1,
        name: "Jhon Smith",
        avatar: avatar1,
        timeWorked: "04:42:27",
        payRate: 10.0,
        totalPay: 120.0,
        billRate: null,
        totalBill: null,
        markAsPaid: false,
        sendPayment: false,
        selectMember: 0,
      },
      {
        id: 2,
        name: "John Doe",
        avatar: avatar2,
        timeWorked: "03:23:49",
        payRate: 18.0,
        totalPay: 245.0,
        billRate: null,
        totalBill: null,
        markAsPaid: false,
        sendPayment: false,
        selectMember: 0,
      },
      {
        id: 3,
        name: "Kevin Peter",
        avatar: avatar3,
        timeWorked: "04:42:27",
        payRate: 22.0,
        totalPay: 320.0,
        billRate: null,
        totalBill: null,
        markAsPaid: false,
        sendPayment: false,
        selectMember: 0,
      },
      {
        id: 4,
        name: "Walter Samual",
        avatar: avatar4,
        timeWorked: "03:23:49",
        payRate: 15.0,
        totalPay: 185.0,
        billRate: null,
        totalBill: null,
        markAsPaid: false,
        sendPayment: false,
        selectMember: 0,
      },
    ],
    selectAllmembers: 0,
  },
];

const CommImageNameComp = (props) => (
  <AssigneesManageImgTextGrid>
    <AssigneesManageRoundImage imageSize={props.size}>
      <RoundMiniImage src={props.imgSource} alt="" imageSize={props.size} />
    </AssigneesManageRoundImage>
    <TableText name>{props.name}</TableText>
  </AssigneesManageImgTextGrid>
);

const PayrollTable = (props) => {
  const [payrollList, setPayrollList] = useState([]);
  const [sortedPayrollList, setSortedPayrollList] = useState([]);
  const [selectAllDatesMembers, setSelectAllDatesMembers] = useState(false);

  const [singlePaymentId, setSinglePaymentId] = useState(null);
  const [multiplePaymentIndex, setMultiplePaymentIndex] = useState(null);
  const [paymentType, setPaymentType] = useState("");
  const [isConfirmPaymentModalOpen, setIsConfirmPaymentModalOpen] =
    useState(false);

  useEffect(() => {
    if (props.payablesData && props.payablesData.length > 0) {
      let dates = getDates();
      let paymentData = [];
      dates.map((date, index) => {
        let object = {};
        object.id = index;
        object.date = moment(date.date).format("ddd, MMMM DD, YYYY");
        object.selectAllmembers = 0;
        object.selectedCount = 0;
        let list = [];
        props.payablesData.map((payable) => {
          if (moment(date.date).format("YYYY-MM-DD") === payable.date) {
            list.push({
              id: payable.id,
              name: payable.user_name,
              avatar: payable.avatar || null,
              color: payable.user_color || null,
              timeWorked: toHHMMSS(payable.total_duration),
              payRate: (payable.pay_rate / 100).toFixed(2),
              totalPay: (payable.total_pay / 100).toFixed(2),
              billRate: (payable.bill_rate / 100).toFixed(2),
              totalBill: (payable.total_bill / 100).toFixed(2),
              currency: payable.currency,
              markAsPaid: false,
              sendPayment: false,
              selectMember: 0,
              requireApproval: payable.require_approval,
            });
          }
        });
        object.memberList = list;
        paymentData.push(object);
      });
      setPayrollList(paymentData);
    } else if (props.payablesData && props.payablesData.length === 0) {
      setPayrollList([]);
    }
  }, [props.payablesData]);

  useEffect(() => {
    let presentState = [...payrollList];
    if (props.sortType.value === "newToOld") {
      let sortedList = presentState.slice().reverse();
      setTimeout(() => {
        setSortedPayrollList(sortedList);
      }, 200);
    }
  }, [props.sortType]);

  useEffect(() => {
    let presentState = [...payrollList];
    if (props.sortType.value === "newToOld") {
      let sortedList = presentState.slice().reverse();
      setTimeout(() => {
        setSortedPayrollList(sortedList);
      }, 200);
    }
  }, [payrollList]);

  const getDates = () => {
    let dates = [];
    let startDate = props.startDate;
    while (moment(startDate).isSameOrBefore(moment(props.endDate))) {
      dates.push({
        date: startDate,
      });
      startDate = moment(startDate).add(1, "days");
    }
    return dates;
  };

  const selectAllDatesMembersFunc = () => {
    let newArr;
    if (props.sortType && props.sortType.value === "newToOld") {
      newArr = [...sortedPayrollList];
    } else {
      newArr = [...payrollList];
    }
    if (!selectAllDatesMembers && newArr) {
      newArr.map((object, index) => {
        if (object.selectAllmembers === 0) {
          object.selectAllmembers = 2;
        }
        object.memberList.map((member, index) => {
          if (member.selectMember === 0) {
            member.selectMember = 3;
          }
        });
      });
    } else {
      newArr.map((object, index) => {
        if (object.selectAllmembers === 2) {
          object.selectAllmembers = 0;
        }
        object.memberList.map((member, index) => {
          if (member.selectMember === 3) {
            member.selectMember = 0;
          }
        });
      });
    }
    if (props.sortType && props.sortType.value === "newToOld") {
      setSortedPayrollList([...newArr]);
    } else {
      setPayrollList([...newArr]);
    }
    setSelectAllDatesMembers(!selectAllDatesMembers);
  };

  const onSinglePersonSelect = (i, index) => {
    let newArr;
    if (props.sortType && props.sortType.value === "newToOld") {
      newArr = [...sortedPayrollList];
    } else {
      newArr = [...payrollList];
    }
    if (newArr[i].memberList[index].selectMember === 0) {
      newArr[i].memberList[index].selectMember = 1;
      newArr[i].selectedCount += 1;
    } else {
      newArr[i].memberList[index].selectMember = 0;
      newArr[i].selectAllmembers = 0;
      newArr[i].selectedCount -= 1;
    }
    if (props.sortType && props.sortType.value === "newToOld") {
      setSortedPayrollList([...newArr]);
    } else {
      setPayrollList([...newArr]);
    }
  };

  const onSingleDateAllPersonSelect = (i) => {
    let newArr;
    if (props.sortType && props.sortType.value === "newToOld") {
      newArr = [...sortedPayrollList];
    } else {
      newArr = [...payrollList];
    }
    if (newArr[i].selectAllmembers === 0) {
      newArr[i].selectAllmembers = 1;
      newArr[i].memberList.map((member, index) => {
        member.selectMember = 1;
      });
    } else {
      newArr[i].selectAllmembers = 0;
      newArr[i].memberList.map((member, index) => {
        member.selectMember = 0;
      });
      newArr[i].selectedCount = 0;
      setSelectAllDatesMembers(false);
    }
    if (props.sortType && props.sortType.value === "newToOld") {
      setSortedPayrollList([...newArr]);
    } else {
      setPayrollList([...newArr]);
    }
  };

  const markAllAsPaid = () => {
    if (
      props.payablesData &&
      props.payablesData.length > 0 &&
      props.selectedOrganization &&
      props.selectedOrganization.id
    ) {
      const payment_ids = [];
      props.payablesData.map((payment) => {
        payment_ids.push(payment.id);
      });
      const payload = {
        organization_id: props.selectedOrganization.id,
        payment_ids,
      };
      props.sendHourlyPayment(payload);
      setSelectAllDatesMembers(!selectAllDatesMembers);
      if (props.sortType) {
        let list;
        if (props.sortType.value === "newToOld") {
          list = [...sortedPayrollList];
        } else {
          list = [...payrollList];
        }
        if (list) {
          list.map((item) => {
            item.selectAllmembers = 0;
            item.memberList &&
              item.memberList.length > 0 &&
              item.memberList.map((member) => {
                member.selectMember = 0;
              });
          });
        }
        if (props.sortType.value === "newToOld") {
          setSortedPayrollList([...list]);
        } else {
          setPayrollList([...list]);
        }
      }
    }
  };

  const sendSingleHourlyPayment = (paymentId) => {
    if (
      paymentId &&
      props.selectedOrganization &&
      props.selectedOrganization.id
    ) {
      let payload = {
        organization_id: props.selectedOrganization.id,
        payment_ids: [paymentId],
      };
      props.sendHourlyPayment(payload);
    }
  };

  const sendSingleDateMultiplePayment = (index) => {
    let newArr;
    if (props.sortType && props.sortType.value === "newToOld") {
      newArr = [...sortedPayrollList];
    } else {
      newArr = [...payrollList];
    }
    if (
      newArr[index] &&
      newArr[index].memberList &&
      newArr[index].memberList.length > 0 &&
      props.selectedOrganization &&
      props.selectedOrganization.id
    ) {
      let payment_ids = [];
      newArr[index].memberList.map((payment) => {
        if (payment.selectMember === 1) {
          payment_ids.push(payment.id);
        }
      });
      let payload = {
        organization_id: props.selectedOrganization.id,
        payment_ids,
      };
      props.sendHourlyPayment(payload);
    }
  };

  const toggleConfirmPaymentModal = (type, paymentId) => {
    setPaymentType(type);
    if (!isConfirmPaymentModalOpen && type !== "all") {
      if (type === "single") {
        setSinglePaymentId(paymentId);
      } else if (type === "multiple") {
        setMultiplePaymentIndex(paymentId);
      }
    } else if (isConfirmPaymentModalOpen) {
      setPaymentType("");
      setSinglePaymentId(null);
      setMultiplePaymentIndex(null);
    }
    setIsConfirmPaymentModalOpen(!isConfirmPaymentModalOpen);
  };

  return (
    <Container padding="30px 0">
      <ConfirmPaymentModal
        isOpen={isConfirmPaymentModalOpen}
        isLoading={props.paymentIsLoading}
        toggle={toggleConfirmPaymentModal}
        paymentType={paymentType}
        singlePaymentId={singlePaymentId}
        multiplePaymentIndex={multiplePaymentIndex}
        sendSingleHourlyPayment={sendSingleHourlyPayment}
        sendSingleDateMultiplePayment={sendSingleDateMultiplePayment}
        markAllAsPaid={markAllAsPaid}
        payrollData={
          props.sortType && props.sortType.value === "newToOld"
            ? sortedPayrollList
            : payrollList
        }
        payablesData={props.payablesData}
        startDate={props.startDate}
        endDate={props.endDate}
      />
      {props.isLoading ? (
        <ComponentCircleLoader />
      ) : (payrollList &&
          payrollList.length > 0 &&
          props.sortType &&
          props.sortType.value === "oldToNew") ||
        (sortedPayrollList &&
          sortedPayrollList.length > 0 &&
          props.sortType &&
          props.sortType.value === "newToOld") ? (
        <>
          <UpperTableSection>
            <CardTitle>
              {props.selectedProject && props.selectedProject.value
                ? props.selectedProject.label
                : "All Projects"}
            </CardTitle>
          </UpperTableSection>

          <PayrollSummaryPayables
            startDate={props.startDate}
            endDate={props.endDate}
            paymentData={props.payablesData}
            toggleConfirmPaymentModal={toggleConfirmPaymentModal}
          />

          {props.sortType && props.sortType.value === "newToOld"
            ? sortedPayrollList.map((payrollItem, i) => {
                if (
                  payrollItem.memberList &&
                  payrollItem.memberList.length > 0
                ) {
                  return (
                    <Fragment key={i}>
                      <ButtonContainer index={i}>
                        <WhiteButton
                          type="cancel"
                          bgColor="#f8f9fc"
                          borderColor="#c2cce1"
                          cursor="auto"
                        >
                          {payrollItem.date}
                        </WhiteButton>
                      </ButtonContainer>
                      <PayrollInfoTable header hourly>
                        <TableItem>
                          <CheckBoxTextContainer>
                            <CheckBox
                              bgColor={
                                payrollItem.selectAllmembers !== 0
                                  ? true
                                  : false
                              }
                              onClick={() => onSingleDateAllPersonSelect(i)}
                            >
                              {payrollItem.selectAllmembers === 1 && (
                                <img
                                  src={TickWhite}
                                  alt="tick"
                                  width="16px"
                                  height="16px"
                                />
                              )}
                            </CheckBox>
                            <TableText>Member Name</TableText>
                          </CheckBoxTextContainer>
                        </TableItem>
                        <TableItem>
                          <TableText>Time Worked</TableText>
                        </TableItem>
                        <TableItem>
                          <TableText>Pay Rate</TableText>
                        </TableItem>
                        <TableItem>
                          <TableText>Total Pay</TableText>
                        </TableItem>
                        {/* <TableItem>
                      <TableText>Bill Rate</TableText>
                    </TableItem>
                    <TableItem>
                      <TableText>Total bill</TableText>
                    </TableItem> */}
                        <TableItem buttonPadding>
                          {(payrollItem.selectAllmembers === 1 ||
                            payrollItem.selectedCount > 1) &&
                            !selectAllDatesMembers && (
                              <PaymentButtonContainer>
                                <ColoredButton
                                  type="gray"
                                  padding="0 15px"
                                  onClick={() =>
                                    // sendSingleDateMultiplePayment(i)
                                    toggleConfirmPaymentModal("multiple", i)
                                  }
                                >
                                  Mark as paid
                                </ColoredButton>
                                {/* <PrimaryButton padding="11px 17px">
                                Send Payment
                              </PrimaryButton> */}
                              </PaymentButtonContainer>
                            )}
                        </TableItem>
                      </PayrollInfoTable>
                      {payrollItem.memberList.map((member, index) => (
                        <PayrollInfoTable hourly key={index}>
                          <TableItem>
                            <CheckBoxTextContainer>
                              <CheckBox
                                onClick={() => onSinglePersonSelect(i, index)}
                              >
                                {member.selectMember === 1 && (
                                  <img
                                    src={Tick}
                                    alt="tick"
                                    width="20px"
                                    height="20px"
                                  />
                                )}
                              </CheckBox>
                              {member.avatar ? (
                                <CommImageNameComp
                                  name={member.name}
                                  imgSource={member.avatar}
                                  size="36px"
                                />
                              ) : (
                                <CommonFlex gap="14px">
                                  <FirstRoundLetterComp
                                    text={member.name}
                                    backColor={index}
                                    color={member.color}
                                  />
                                  <TableText name>{member.name}</TableText>
                                </CommonFlex>
                              )}
                            </CheckBoxTextContainer>
                          </TableItem>
                          <TableItem>
                            <CommonFlex gap="12px">
                              <TableText name> {member.timeWorked} </TableText>
                              <RequireApprovalDiv
                                isRequired={member.requireApproval}
                              >
                                <CommonImage
                                  src={tickWhiteBold}
                                  alt=""
                                  size="9px"
                                />
                                <RequireTooltipContainer>
                                  <CommonTooltip
                                    tooltipText={`Timesheet approval is${
                                      !member.requireApproval ? " not" : ""
                                    } required`}
                                    left="-119px"
                                    top="-45px"
                                    width="225px"
                                    height="28px"
                                    fontSize="11px"
                                    padding="5px 12px"
                                  />
                                </RequireTooltipContainer>
                              </RequireApprovalDiv>
                            </CommonFlex>
                          </TableItem>
                          <TableItem>
                            <TableText name>
                              {`${currencySymbolMap[member.currency]} ${
                                member.payRate
                              }`}
                            </TableText>
                          </TableItem>
                          <TableItem>
                            <TableText name>
                              {`${currencySymbolMap[member.currency]} ${
                                member.totalPay
                              }`}
                            </TableText>
                          </TableItem>
                          {/* <TableItem>
                        <TableText name>
                          {member.billRate !== null
                            ? `${member.currency} ${member.billRate}`
                            : `Bill rate is not set`}
                        </TableText>
                      </TableItem>
                      <TableItem>
                        <TableText name>
                          {member.totalBill !== null
                            ? `${member.currency} ${member.totalBill}`
                            : `-`}
                        </TableText>
                      </TableItem> */}
                          <TableItem buttonPadding>
                            {member.selectMember === 1 &&
                              payrollItem.selectedCount < 2 &&
                              payrollItem.selectAllmembers === 0 &&
                              !selectAllDatesMembers && (
                                <PaymentButtonContainer>
                                  <ColoredButton
                                    type="gray"
                                    padding="0 15px"
                                    onClick={() =>
                                      // sendSingleHourlyPayment(member.id)
                                      toggleConfirmPaymentModal(
                                        "single",
                                        member.id
                                      )
                                    }
                                  >
                                    Mark as paid
                                  </ColoredButton>
                                  {/* <PrimaryButton padding="11px 17px">
                                    Send Payment
                                  </PrimaryButton> */}
                                </PaymentButtonContainer>
                              )}
                          </TableItem>
                        </PayrollInfoTable>
                      ))}
                    </Fragment>
                  );
                }
              })
            : payrollList.map((payrollItem, i) => {
                if (
                  payrollItem.memberList &&
                  payrollItem.memberList.length > 0
                ) {
                  return (
                    <Fragment key={i}>
                      <ButtonContainer index={i}>
                        <WhiteButton
                          type="cancel"
                          bgColor="#f8f9fc"
                          borderColor="#c2cce1"
                          cursor="auto"
                        >
                          {payrollItem.date}
                        </WhiteButton>
                      </ButtonContainer>
                      <PayrollInfoTable header hourly>
                        <TableItem>
                          <CheckBoxTextContainer>
                            <CheckBox
                              bgColor={
                                payrollItem.selectAllmembers !== 0
                                  ? true
                                  : false
                              }
                              onClick={() => onSingleDateAllPersonSelect(i)}
                            >
                              {payrollItem.selectAllmembers === 1 && (
                                <img
                                  src={TickWhite}
                                  alt="tick"
                                  width="16px"
                                  height="16px"
                                />
                              )}
                            </CheckBox>
                            <TableText>Member Name</TableText>
                          </CheckBoxTextContainer>
                        </TableItem>
                        <TableItem>
                          <TableText>Time Worked</TableText>
                        </TableItem>
                        <TableItem>
                          <TableText>Pay Rate</TableText>
                        </TableItem>
                        <TableItem>
                          <TableText>Total Pay</TableText>
                        </TableItem>
                        {/* <TableItem>
                      <TableText>Bill Rate</TableText>
                    </TableItem>
                    <TableItem>
                      <TableText>Total bill</TableText>
                    </TableItem> */}
                        <TableItem buttonPadding>
                          {(payrollItem.selectAllmembers === 1 ||
                            payrollItem.selectedCount > 1) &&
                            !selectAllDatesMembers && (
                              <PaymentButtonContainer>
                                <ColoredButton
                                  type="gray"
                                  padding="0 15px"
                                  onClick={() =>
                                    // sendSingleDateMultiplePayment(i)
                                    toggleConfirmPaymentModal("multiple", i)
                                  }
                                >
                                  Mark as paid
                                </ColoredButton>
                                {/* <CommonColorButton padding="11px 17px">
                                Send Payment
                              </CommonColorButton> */}
                              </PaymentButtonContainer>
                            )}
                        </TableItem>
                      </PayrollInfoTable>
                      {payrollItem.memberList.map((member, index) => (
                        <PayrollInfoTable hourly key={index}>
                          <TableItem>
                            <CheckBoxTextContainer>
                              <CheckBox
                                onClick={() => onSinglePersonSelect(i, index)}
                              >
                                {member.selectMember === 1 && (
                                  <img
                                    src={Tick}
                                    alt="tick"
                                    width="20px"
                                    height="20px"
                                  />
                                )}
                              </CheckBox>
                              {member.avatar ? (
                                <CommImageNameComp
                                  name={member.name}
                                  imgSource={member.avatar}
                                  size="36px"
                                />
                              ) : (
                                <CommonFlex gap="14px">
                                  <FirstRoundLetterComp
                                    text={member.name}
                                    backColor={index}
                                    color={member.color}
                                  />
                                  <TableText name>{member.name}</TableText>
                                </CommonFlex>
                              )}
                            </CheckBoxTextContainer>
                          </TableItem>
                          <TableItem>
                            <CommonFlex gap="12px">
                              <TableText name> {member.timeWorked} </TableText>
                              <RequireApprovalDiv
                                isRequired={member.requireApproval}
                              >
                                <CommonImage
                                  src={tickWhiteBold}
                                  alt=""
                                  size="9px"
                                />
                                <RequireTooltipContainer>
                                  <CommonTooltip
                                    tooltipText={`Timesheet approval is${
                                      !member.requireApproval ? " not" : ""
                                    } required`}
                                    left="-119px"
                                    top="-45px"
                                    width="225px"
                                    height="28px"
                                    fontSize="11px"
                                    padding="5px 12px"
                                  />
                                </RequireTooltipContainer>
                              </RequireApprovalDiv>
                            </CommonFlex>
                          </TableItem>
                          <TableItem>
                            <TableText name>
                              {`${currencySymbolMap[member.currency]} ${
                                member.payRate
                              }`}
                            </TableText>
                          </TableItem>
                          <TableItem>
                            <TableText name>
                              {`${currencySymbolMap[member.currency]} ${
                                member.totalPay
                              }`}
                            </TableText>
                          </TableItem>
                          {/* <TableItem>
                        <TableText name>
                          {member.billRate !== null
                            ? `${member.currency} ${member.billRate}`
                            : `Bill rate is not set`}
                        </TableText>
                      </TableItem>
                      <TableItem>
                        <TableText name>
                          {member.totalBill !== null
                            ? `${member.currency} ${member.totalBill}`
                            : `-`}
                        </TableText>
                      </TableItem> */}
                          <TableItem buttonPadding>
                            {member.selectMember === 1 &&
                              payrollItem.selectAllmembers === 0 &&
                              payrollItem.selectedCount < 2 &&
                              !selectAllDatesMembers && (
                                <PaymentButtonContainer>
                                  <ColoredButton
                                    type="gray"
                                    padding="0 15px"
                                    onClick={() =>
                                      //sendSingleHourlyPayment(member.id)
                                      toggleConfirmPaymentModal(
                                        "single",
                                        member.id
                                      )
                                    }
                                  >
                                    Mark as paid
                                  </ColoredButton>
                                  {/* <CommonColorButton padding="11px 17px">
                                    Send Payment
                                  </CommonColorButton> */}
                                </PaymentButtonContainer>
                              )}
                          </TableItem>
                        </PayrollInfoTable>
                      ))}
                    </Fragment>
                  );
                }
              })}
        </>
      ) : (
        <NoDataComponent title="No payrolls found." />
      )}
    </Container>
  );
};

export default PayrollTable;
