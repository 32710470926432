import moment from "moment";
import {
  GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT,
  GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT_SUCCESS,
  GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT_FAILURE,
  GET_URL_USAGE_REPORT,
  GET_URL_USAGE_REPORT_SUCCESS,
  GET_URL_USAGE_REPORT_FAILURE,
  GET_APP_USAGE_REPORT,
  GET_APP_USAGE_REPORT_SUCCESS,
  GET_APP_USAGE_REPORT_FAILURE,
  GET_MANUAL_TIME_REPORT,
  GET_MANUAL_TIME_REPORT_SUCCESS,
  GET_MANUAL_TIME_REPORT_FAILURE,
  GET_APP_TIME_INFO_REPORT,
  GET_APP_TIME_INFO_REPORT_SUCCESS,
  GET_APP_TIME_INFO_REPORT_FAILURE,
  GET_REPORT_NOTES,
  GET_REPORT_NOTES_SUCCESS,
  GET_REPORT_NOTES_FAILURE,
  LOGIN_PROFILE_CREATED_RESET,
  GET_WEEKLY_ACTIVITY_AND_TIME_REPORT,
  GET_WEEKLY_ACTIVITY_AND_TIME_REPORT_SUCCESS,
  GET_WEEKLY_ACTIVITY_AND_TIME_REPORT_FAILURE,
  GET_CUSTOM_REPORT,
  GET_CUSTOM_REPORT_SUCCESS,
  GET_CUSTOM_REPORT_FAILURE,
  CREATE_CUSTOM_REPORT,
  CREATE_CUSTOM_REPORT_SUCCESS,
  CREATE_CUSTOM_REPORT_FAILURE,
  DELETE_CUSTOM_REPORT,
  DELETE_CUSTOM_REPORT_SUCCESS,
  DELETE_CUSTOM_REPORT_FAILURE,
  GET_CUSTOM_REPORT_DETAILS,
  GET_CUSTOM_REPORT_DETAILS_SUCCESS,
  GET_CUSTOM_REPORT_DETAILS_FAILURE,
  UPDATE_CUSTOM_REPORT,
  UPDATE_CUSTOM_REPORT_SUCCESS,
  UPDATE_CUSTOM_REPORT_FAILURE,
} from "../../modules/constants";
import { createNotification } from "../../modules/notificationManager";

// ------------------------------------
// Action handler methods
// ------------------------------------

export const handleGetTimeActivityReport = (state, action) => {
  return {
    ...state,
    isLoading: true,
    noData: false,
    averageActivity: null,
  };
};
export const handleGetTimeActivityReportSuccess = (state, action) => {
  const {
    payload: { data, startDate, endDate },
  } = action;
  for (let i in data) {
    data[i] = data[i]?.sort((a, b) =>
      a.member_name.localeCompare(b.member_name)
    );
  }
  return {
    ...state,
    isLoading: false,
    noData: true,
    averageActivity: {
      data,
      startDate,
      endDate,
    },
  };
};
export const handleGetTimeActivityReportFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    noData: false,
  };
};

export const handleWeeklyTimeAndActivityRequest = (state, action) => {
  return {
    ...state,
    isWeeklyDataLoading: true,
  };
};

export const handleWeeklyTimeAndActivityRequestSuccess = (state, action) => {
  const {
    payload: { data, startDate, endDate },
  } = action;
  for (let i in data) {
    data[i] = data[i]?.sort((a, b) =>
      a.member_name.localeCompare(b.member_name)
    );
  }
  return {
    ...state,
    isWeeklyDataLoading: false,
    weeklyData: { data, startDate, endDate },
  };
};

export const handleWeeklyTimeAndActivityRequestFailure = (state, action) => {
  return {
    ...state,
    isWeeklyDataLoading: false,
    weeklyData: undefined,
  };
};

export const handleManualTimeRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    noData: false,
  };
};
export const handleManualTimeRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  const reportData = JSON.parse(JSON.stringify(data));
  reportData.sort(
    (a, b) =>
      moment(Object.keys(b)[0]).unix() - moment(Object.keys(a)[0]).unix()
  );
  return {
    ...state,
    isLoading: false,
    noData: true,
    manualTimeData: reportData,
  };
};
export const handleManualTimeRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    noData: false,
    manualTimeData: undefined,
  };
};

export const handleUrlUsageRequest = (state, action) => {
  return {
    ...state,
  };
};
export const handleUrlUsageRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    urlReportData: data,
  };
};
export const handleUrlUsageRequestFailure = (state, action) => {
  return {
    ...state,
    urlReportData: undefined,
  };
};

export const handleAppUsageRequest = (state, action) => {
  return {
    ...state,
    isLoading: true,
    noData: false,
  };
};
export const handleAppUsageRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  const appReportData = JSON.parse(JSON.stringify(data));
  // appReportData.sort(
  //     (a, b) => moment(b.working_day).unix() - moment(a.working_day).unix()
  //   );
  return {
    ...state,
    isLoading: false,
    noData: true,
    appReportData: appReportData,
  };
};
export const handleAppUsageRequestFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    noData: false,
    appReportData: undefined,
  };
};

//App time info
export const handleAppTimeInfoRequest = (state, action) => {
  return {
    ...state,
  };
};
export const handleAppTimeInfoRequestSuccess = (state, action) => {
  const {
    payload: { data, startDate, endDate },
  } = action;
  return {
    ...state,
    appTimeData: { data, startDate, endDate },
  };
};
export const handleAppTimeInfoRequestFailure = (state, action) => {
  return {
    ...state,
    appTimeData: undefined,
  };
};

export const handleGetReportNotesRequest = (state, action) => {
  return {
    ...state,
    reportNotesLoading: true,
  };
};
export const handleGetReportNotesRequestSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;

  if (data && data.results) {
    return {
      ...state,
      reportNotesLoading: false,
      notes: data.results,
    };
  }
  return {
    ...state,
    reportNotesLoading: false,
    notes: [],
  };
};
export const handleGetReportNotesRequestFailure = (state, action) => {
  return {
    ...state,
    reportNotesLoading: false,
    notes: [],
  };
};

export const handleLogoutRequest = (state, action) => {
  return {
    ...state,
    ...initialState,
  };
};

export const handleGetCustomReport = (state, action) => {
  return {
    ...state,
    isLoading: true,
    customReportList: null,
    createdReport: null,
    createReportFailed: false,
    customReportDetails: null,
  };
};
export const handleGetCustomReportSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    customReportList: data,
  };
};
export const handleGetCustomReportFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    customReportList: null,
  };
};

export const handleUpdateCustomReport = (state, action) => {
  return {
    ...state,
    isLoading: true,
    createdReport: null,
    createReportFailed: false,
  };
};
export const handleUpdateCustomReportSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    createdReport: data,
    createReportFailed: false,
  };
};
export const handleUpdateCustomReportFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    createdReport: null,
    createReportFailed: true,
  };
};

export const handleCreateCustomReport = (state, action) => {
  return {
    ...state,
    isLoading: true,
    createdReport: null,
    createReportFailed: false,
  };
};
export const handleCreateCustomReportSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;
  return {
    ...state,
    isLoading: false,
    createdReport: data,
    createReportFailed: false,
  };
};
export const handleCreateCustomReportFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    createdReport: null,
    createReportFailed: true,
  };
};
export const handleDeleteCustomReport = (state, action) => {
  return {
    ...state,
    isDeleteLoading: true,
    deletedReports: null,
  };
};
export const handleDeleteCustomReportSuccess = (state, action) => {
  const {
    payload: { report_ids },
  } = action;
  const newCustomReportList = state?.customReportList?.filter(
    (report) => !report_ids?.includes(report.id)
  );
  return {
    ...state,
    isDeleteLoading: false,
    deletedReports: report_ids,
    customReportList: newCustomReportList,
  };
};
export const handleDeleteCustomReportFailure = (state, action) => {
  createNotification(
    "error",
    action?.payload?.detail
      ? action?.payload?.detail
      : "Failed to delete report"
  );
  return {
    ...state,
    isDeleteLoading: false,
    deletedReports: null,
  };
};

export const handleGetCustomReportDetails = (state, action) => {
  return {
    ...state,
    isLoading: true,
    customReportDetails: null,
  };
};
export const handleGetCustomReportDetailsSuccess = (state, action) => {
  const {
    payload: { data },
  } = action;

  return {
    ...state,
    isLoading: false,
    customReportDetails: data,
  };
};
export const handleGetCustomReportDetailsFailure = (state, action) => {
  return {
    ...state,
    isLoading: false,
    customReportDetails: null,
  };
};

// Action Handlers
const ACTION_HANDLERS = {
  [GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT]: handleGetTimeActivityReport,
  [GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT_SUCCESS]:
    handleGetTimeActivityReportSuccess,
  [GET_DAY_WISE_ACTIVITY_AND_TIME_REPORT_FAILURE]:
    handleGetTimeActivityReportFailure,
  [GET_WEEKLY_ACTIVITY_AND_TIME_REPORT]: handleWeeklyTimeAndActivityRequest,
  [GET_WEEKLY_ACTIVITY_AND_TIME_REPORT_SUCCESS]:
    handleWeeklyTimeAndActivityRequestSuccess,
  [GET_WEEKLY_ACTIVITY_AND_TIME_REPORT_FAILURE]:
    handleWeeklyTimeAndActivityRequestFailure,
  [GET_CUSTOM_REPORT]: handleGetCustomReport,
  [GET_CUSTOM_REPORT_SUCCESS]: handleGetCustomReportSuccess,
  [GET_CUSTOM_REPORT_FAILURE]: handleGetCustomReportFailure,

  [GET_CUSTOM_REPORT_DETAILS]: handleGetCustomReportDetails,
  [GET_CUSTOM_REPORT_DETAILS_SUCCESS]: handleGetCustomReportDetailsSuccess,
  [GET_CUSTOM_REPORT_DETAILS_FAILURE]: handleGetCustomReportDetailsFailure,

  [CREATE_CUSTOM_REPORT]: handleCreateCustomReport,
  [CREATE_CUSTOM_REPORT_SUCCESS]: handleCreateCustomReportSuccess,
  [CREATE_CUSTOM_REPORT_FAILURE]: handleCreateCustomReportFailure,

  [UPDATE_CUSTOM_REPORT]: handleUpdateCustomReport,
  [UPDATE_CUSTOM_REPORT_SUCCESS]: handleUpdateCustomReportSuccess,
  [UPDATE_CUSTOM_REPORT_FAILURE]: handleUpdateCustomReportFailure,

  [DELETE_CUSTOM_REPORT]: handleDeleteCustomReport,
  [DELETE_CUSTOM_REPORT_SUCCESS]: handleDeleteCustomReportSuccess,
  [DELETE_CUSTOM_REPORT_FAILURE]: handleDeleteCustomReportFailure,

  [GET_MANUAL_TIME_REPORT]: handleManualTimeRequest,
  [GET_MANUAL_TIME_REPORT_SUCCESS]: handleManualTimeRequestSuccess,
  [GET_MANUAL_TIME_REPORT_FAILURE]: handleManualTimeRequestFailure,

  [GET_URL_USAGE_REPORT]: handleUrlUsageRequest,
  [GET_URL_USAGE_REPORT_SUCCESS]: handleUrlUsageRequestSuccess,
  [GET_URL_USAGE_REPORT_FAILURE]: handleUrlUsageRequestFailure,

  [GET_APP_USAGE_REPORT]: handleAppUsageRequest,
  [GET_APP_USAGE_REPORT_SUCCESS]: handleAppUsageRequestSuccess,
  [GET_APP_USAGE_REPORT_FAILURE]: handleAppUsageRequestFailure,

  [GET_APP_TIME_INFO_REPORT]: handleAppTimeInfoRequest,
  [GET_APP_TIME_INFO_REPORT_SUCCESS]: handleAppTimeInfoRequestSuccess,
  [GET_APP_TIME_INFO_REPORT_FAILURE]: handleAppTimeInfoRequestFailure,

  [GET_REPORT_NOTES]: handleGetReportNotesRequest,
  [GET_REPORT_NOTES_SUCCESS]: handleGetReportNotesRequestSuccess,
  [GET_REPORT_NOTES_FAILURE]: handleGetReportNotesRequestFailure,

  [LOGIN_PROFILE_CREATED_RESET]: handleLogoutRequest,
};

// default initial state
const initialState = {
  isLoading: false,
  reportNotesLoading: false,
  noData: false,
  averageActivity: null,
  weeklyData: null,
  manualTimeData: [],
  appReportData: [],
  urlReportData: [],
  appTimeData: [],
  notes: [],
  createReportFailed: false,
};

export default function reportReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
