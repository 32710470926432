//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";
//Constants
import {
  GET_ALL_TASK_LIST,
  GET_ALL_TASK_LIST_SUCCESS,
  GET_ALL_TASK_LIST_FAILURE,
  UPDATE_TASK,
  UPDATE_TASK_SUCCESS,
  UPDATE_TASK_FAILURE,
} from "../../modules/constants";

export function* getTaskList({ payload }) {
  if (!payload) return;
  const { organization_id, page, name, project_id, user_id, status } = payload;
  try {
    const url = `${organization_id}/projects/tasks/all/?status=${status}${
      page ? `&page=${page}` : ""
    }${name ? `&name=${name}` : ""}${
      project_id ? `&project_id=${project_id}` : ""
    }${user_id ? `&members=${user_id}` : ""}`;

    const response = yield defaultApi(url, "GET", payload);

    yield put({
      type: GET_ALL_TASK_LIST_SUCCESS,
      payload: { data: response, project_id },
    });
  } catch (err) {
    yield put({
      type: GET_ALL_TASK_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* updateTask({ payload }) {
  const { organization_id, name, project_id, task_id } = payload;
  const details = {
    id: task_id,
    name: name,
    project_id: project_id,
  };
  try {
    const url = `${organization_id}/projects/${project_id}/tasks/${task_id}/`;
    if (details.name == ""){
      throw new Error("Task name required.")
    }

    const response = yield defaultApi(url, "PATCH", details);
    yield put({
      type: UPDATE_TASK_SUCCESS,
      payload: { data: response, notify: "PATCH" },
    });
  } catch (err) {
    if (!details.name) {
      yield put({
        type: UPDATE_TASK_FAILURE,
        name: details.name,
        payload: err.message,
      });
    }
    else {
      yield put({
        type: UPDATE_TASK_FAILURE,
        payload: err
      });
    }
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_ALL_TASK_LIST, getTaskList),
    takeLatest(UPDATE_TASK, updateTask),
  ]);
}
