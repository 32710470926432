import { GET_ALL_TASK_LIST, UPDATE_TASK } from "../../modules/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function getTaskList(value) {
  return {
    type: GET_ALL_TASK_LIST,
    payload: value,
  };
}

export function updateTask(value) {
  return {
    type: UPDATE_TASK,
    payload: value,
  };
}

export const actions = {
  getTaskList,
  updateTask,
};
