import { useState, useEffect } from "react";
import { LightgalleryProvider } from "react-lightgallery";
import moment from "moment";
import $ from "jquery";

import {
  FlexSection,
  ActivityTextSection,
} from "../../../styledComponents/members";
import {
  CardsContainer,
  RoundCircle,
} from "../../../styledComponents/Activity";
import { CommonText } from "../../../styledComponents/common";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";

import { timeRangeList } from "./timeRangeList";
import SingleCard from "./SingleCard";

const CardsList = (props) => {
  const [timeRange, setTimeRange] = useState([]);
  const [sortedTimeRange, setSortedTimeRange] = useState([]);

  const { screenshots, sortType, selectedOrganization, screenshotBlur } = props;

  useEffect(() => {
    if (screenshots && screenshots.length > 0) {
      // let timeList = [...timeRangeList];
      let timeList = JSON.parse(JSON.stringify(timeRangeList));
      const screenshotList = [...screenshots];
      // timeList.map((time) => {
      screenshotList.map((screenshot) => {
        const taken_hour = screenshot.start_timestamp.hours();
        if (
          timeList[taken_hour] &&
          timeList[taken_hour].id !== null &&
          timeList[taken_hour].id === taken_hour
        ) {
          timeList[taken_hour].screenshots.push(screenshot);
        }
      });
      // let filteredScreenshots = screenshotList.filter((screenshot) => {
      // let taken;
      // if (
      //   screenshot.activity &&
      //   screenshot.activity.screenshot_timestamp !== null &&
      //   moment(screenshot.converted_start_time).isSame(
      //     moment(screenshot.activity.screenshot_timestamp),
      //     "day"
      //   )
      // ) {
      //   taken = moment(screenshot.activity.screenshot_timestamp).hours();
      // } else {
      //   taken = moment(screenshot.converted_start_time).hours();
      // }
      // taken = screenshot.start_timestamp.hours();

      // console.log(taken);

      // let startTimeRange = parseInt(time.startTimeRange.split(":")[0]);
      // let endTimeRange = parseInt(time.endTimeRange.split(":")[0]);

      // return taken === startTimeRange && taken < endTimeRange;
      // });
      // time.screenshots = [...filteredScreenshots];
      // });
      setTimeRange([...timeList]);
    } else if (screenshots && screenshots.length === 0) {
      setTimeRange([]);
    }
  }, [screenshots]);

  useEffect(() => {
    if (sortType.value === "NewToOld") {
      if (screenshots && screenshots.length > 0) {
        const timeList = JSON.parse(
          JSON.stringify(timeRangeList.slice().reverse())
        );
        const screenshotList = [...screenshots];
        screenshotList.map((screenshot) => {
          const taken_hour = screenshot.start_timestamp.hours();
          if (
            timeList[23 - taken_hour] &&
            timeList[23 - taken_hour].id !== null &&
            timeList[23 - taken_hour].id === taken_hour
          ) {
            timeList[23 - taken_hour].screenshots.push(screenshot);
          }
        });
        setSortedTimeRange([...timeList]);
      } else if (screenshots && screenshots.length === 0) {
        setSortedTimeRange([]);
      }
    }
  }, [sortType]);

  const handleScreenshotBlur = () => {
    const image = $(".lg-img-wrap").children("img");
    const thumbnail = $(".lg-thumb-item").children("img");
    if (screenshotBlur) {
      image.addClass("blurred-screenshot-image");
      thumbnail.addClass("blurred-screenshot-thumbnail");
    } else {
      image.removeClass("blurred-screenshot-image");
      thumbnail.removeClass("blurred-screenshot-thumbnail");
    }
  };

  return (
    <div style={{ padding: `20px 0` }}>
      {sortType && sortType.value === "OldToNew" ? (
        <>
          {timeRange && timeRange.length > 0 && (
            <LightgalleryProvider
              lightgallerySettings={{
                width: "100%",
                height: "100%",
                hideControlOnEnd: true,
                loop: false,
                download: false,
                hideBarsDelay: 1000,
                mousewheel: true,
              }}
              onAfterOpen={() => handleScreenshotBlur()}
              onSlideItemLoad={() => handleScreenshotBlur()}
            >
              {timeRange.map(
                (time, index) =>
                  time.screenshots &&
                  time.screenshots.length > 0 && (
                    <ActivityTextSection
                      style={{ marginBottom: `20px` }}
                      key={index}
                    >
                      <FlexSection alignItems="center" gap="12px">
                        <RoundCircle />
                        <CommonText title>
                          {`${moment(time.startTimeRange, "H:mm").format(
                            "h:mm A"
                          )} - ${moment(time.endTimeRange, "H:mm").format(
                            "h:mm A"
                          )} `}
                        </CommonText>
                      </FlexSection>
                      <CardsContainer>
                        {time.screenshots.map((item, i) => (
                          <SingleCard
                            screenshot={item}
                            key={i}
                            selectedOrganization={selectedOrganization}
                            selectedMemberId={props.selectedMemberId}
                            addNotes={props.addNotes}
                            deleteScreenshot={props.deleteScreenshot}
                            user={props.user}
                            allNotesIsLoading={props.allNotesIsLoading}
                            addNoteIsLoading={props.addNoteIsLoading}
                            deleteIsLoading={props.deleteIsLoading}
                          />
                        ))}
                      </CardsContainer>
                    </ActivityTextSection>
                  )
              )}
            </LightgalleryProvider>
          )}
          {timeRange && timeRange.length === 0 && (
            <NoDataComponent title="No screenshots found." />
          )}
        </>
      ) : (
        <>
          {sortedTimeRange && sortedTimeRange.length > 0 && (
            <LightgalleryProvider
              lightgallerySettings={{
                width: "100%",
                height: "100%",
                hideControlOnEnd: true,
                loop: false,
                download: false,
                hideBarsDelay: 1000,
                mousewheel: true,
              }}
              onAfterOpen={() => handleScreenshotBlur()}
              onSlideItemLoad={() => handleScreenshotBlur()}
            >
              {sortedTimeRange.map(
                (time, index) =>
                  time.screenshots &&
                  time.screenshots.length > 0 && (
                    <ActivityTextSection
                      style={{ marginBottom: `20px` }}
                      key={index}
                    >
                      <FlexSection alignItems="center" gap="12px">
                        <RoundCircle />
                        <CommonText title>
                          {`${moment(time.startTimeRange, "H:mm").format(
                            "h:mm A"
                          )} - ${moment(time.endTimeRange, "H:mm").format(
                            "h:mm A"
                          )} `}
                        </CommonText>
                      </FlexSection>
                      <CardsContainer>
                        {time.screenshots.map((item, i) => (
                          <SingleCard
                            screenshot={item}
                            key={i}
                            selectedOrganization={selectedOrganization}
                            selectedMemberId={props.selectedMemberId}
                            addNotes={props.addNotes}
                            deleteScreenshot={props.deleteScreenshot}
                            user={props.user}
                            allNotesIsLoading={props.allNotesIsLoading}
                            addNoteIsLoading={props.addNoteIsLoading}
                            deleteIsLoading={props.deleteIsLoading}
                          />
                        ))}
                      </CardsContainer>
                    </ActivityTextSection>
                  )
              )}
            </LightgalleryProvider>
          )}
          {sortedTimeRange && sortedTimeRange.length === 0 && (
            <NoDataComponent title="No screenshots found." />
          )}
        </>
      )}
    </div>
  );
};

export default CardsList;
