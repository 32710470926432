import React, { useState, useEffect } from "react";
import moment from "moment";

import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";

import LeaveFilter from "./LeaveFilter";
import LeaveTable from "./LeaveTable";

import NoLeave from "../../assets/img/no-leave-request-artwork.svg";

import { PageTitle, Container } from "../../styledComponents/common";

import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";

import ApplyLeaveModal from "./ApplyLeaveModal";
import ApproveLeaveModal from "./ApproveRejectLeaveModal";

const Leave = (props) => {
  const [startDate, setStartDate] = useState(
    moment().startOf("isoWeek").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("isoWeek").format("YYYY-MM-DD")
  );

  const [selectedMember, setSelectedMember] = useState("");
  const [memberOptions, setMemberOptions] = useState([]);
  const [filterPayload, setFilterPayload] = useState({});
  const [tabSelect, setTabSelect] = useState("pending");

  const [leaveId, setLeaveId] = useState(null);
  const [actionType, setActionType] = useState(null);

  const [isApplyLeaveModalVisible, setIsApplyLeaveModalVisible] =
    useState(false);
  const [isApproveRejectModalOpen, setIsApproveRejectModalOpen] =
    useState(false);

  const {
    selectedOrganization,
    organizationMembersList,
    getOrganizationMembersList,
    approveOrRejectLeave,
    createLeave,
    history,

    getLeavesList,
    isLoading,
    leavesList,
    approveOrRejectIsLoading,
    createLeaveIsLoading,
    status,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      setSelectedMember("");
      const payload = {
        organization_id: selectedOrganization.id,
        start_date: startDate,
        end_date: endDate,
      };

      getOrganizationMembersList({
        organization_id: selectedOrganization.id,
      });

      getLeavesList({
        status: tabSelect,
        organization_id: selectedOrganization.id,
        start_date: startDate,
        end_date: endDate,
      });
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (organizationMembersList && organizationMembersList.length > 0) {
      setMemberOptions(organizationMembersList);
    } else if (
      organizationMembersList &&
      organizationMembersList.length === 0
    ) {
      setMemberOptions([]);
    }
  }, [organizationMembersList]);

  useEffect(() => {}, [leavesList]);

  const onTabSwitch = (value) => {
    if (selectedOrganization && selectedOrganization.id && value) {
      const payload = {
        organization_id: selectedOrganization.id,
        start_date: startDate,
        end_date: endDate,
        status: value,
      };
      getLeavesList(payload);
    }

    setTabSelect(value);
  };

  const actionsReq = (payload) => {
    return getLeavesList(payload);
  };

  const onMemberSelect = (e) => {
    if (selectedOrganization && selectedOrganization.id && e) {
      const payload = {
        organization_id: selectedOrganization.id,
        start_date: startDate,
        end_date: endDate,
        status: tabSelect,
        user_id: e.id,
      };
      getLeavesList(payload);
    }
  };

  const onDateSelect = () => {
    let organizationId = selectedOrganization ? selectedOrganization.id : "";
    if (organizationId) {
      const payload = {
        organization_id: organizationId,
        start_date: startDate === "Invalid date" ? endDate : startDate,
        end_date: endDate === "Invalid date" ? startDate : endDate,
        status: tabSelect,
      };
      if (selectedMember && selectedMember.id) {
        payload.user_id = [selectedMember.id];
      }

      setFilterPayload(payload);
      actionsReq(payload);
    }
  };

  const onClearMember = () => {
    const { selectedOrganization } = props;

    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        start_date: startDate,
        end_date: endDate,
        status: tabSelect,
      };
      getLeavesList(payload);
    }
  };

  const changeDateRange = (e) => {
    const { startDate, endDate } = e;
    if (startDate) {
      setStartDate(moment(startDate).format("YYYY-MM-DD"));
      if (!endDate) {
        setEndDate(moment(startDate).format("YYYY-MM-DD"));
      }
    }
    if (endDate) {
      setEndDate(moment(endDate).format("YYYY-MM-DD"));
      if (!startDate) {
        setStartDate(moment(endDate).format("YYYY-MM-DD"));
      }
    }
  };

  const selectLastWeek = () => {
    setStartDate(moment().subtract(6, "days").format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
  };
  const selectLastMonth = () => {
    setStartDate(moment().subtract(29, "days").format("YYYY-MM-DD"));
    setEndDate(moment().format("YYYY-MM-DD"));
  };

  const toggle = () => {
    setIsApproveRejectModalOpen(!isApproveRejectModalOpen);
  };

  const setIdType = (id, type) => {
    setActionType(type);
    setLeaveId(id);
    toggle();
  };

  const handleLeaveApproveReject = (status) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      leaveId &&
      actionType
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        leave_id: leaveId,
        status:
          actionType === "reject"
            ? "rejected"
            : actionType === "accept"
            ? "accepted"
            : "delete",
      };

      approveOrRejectLeave(payload);

      setTimeout(() => {
        setIsApproveRejectModalOpen(!isApproveRejectModalOpen);
      }, 400);
    }
  };

  const toggleApplyLeaveModal = () => {
    setIsApplyLeaveModalVisible(!isApplyLeaveModalVisible);
  };

  const handleApplyLeave = (leave) => {
    if (selectedOrganization && selectedOrganization.id && leave) {
      const payload = {
        organization_id: leave.organization_id,
        start: moment(leave.start_time).format("YYYY-MM-DD"),
        end: moment(leave.end_time).format("YYYY-MM-DD"),
        message: leave.comment,
        filterStart: startDate,
        filterEnd: endDate,
      };
      createLeave(payload);

      setTimeout(() => {
        setIsApplyLeaveModalVisible(!isApplyLeaveModalVisible);
      }, 400);
    }
  };

  return (
    <div className="content">
      <PageTitle>Leave Management</PageTitle>
      <LeaveFilter
        onTabSwitch={onTabSwitch}
        tabSelect={tabSelect}
        history={history}
        toggle={toggleApplyLeaveModal}
        startDate={startDate}
        endDate={endDate}
        onDateSelect={onDateSelect}
        changeDateRange={changeDateRange}
        selectLastWeek={selectLastWeek}
        selectLastMonth={selectLastMonth}
        onMemberSelect={onMemberSelect}
        onClearMember={onClearMember}
        organizationMembersList={organizationMembersList}
      />

      <ApplyLeaveModal
        isOpen={isApplyLeaveModalVisible}
        toggle={toggleApplyLeaveModal}
        handleApplyLeave={handleApplyLeave}
        selectedOrganization={selectedOrganization}
        isLoading={createLeaveIsLoading}
      />

      <ApproveLeaveModal
        leaveId={leaveId}
        isOpen={isApproveRejectModalOpen && leaveId}
        toggle={toggle}
        handleLeaveApproveReject={handleLeaveApproveReject}
        actionType={actionType}
        isLoading={approveOrRejectIsLoading}
      />

      <Container style={{ padding: `30px`, marginTop: `20px` }}>
        {props.isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            {leavesList && leavesList.size > 0 ? (
              Array.from(leavesList).map((leave, index) => {
                return (
                  <LeaveTable
                    leave={leave}
                    index={index}
                    key={index}
                    tabSelect={tabSelect}
                    status={status}
                    modalToggle={setIdType}
                    selectedOrganization={selectedOrganization}
                  />
                );
              })
            ) : (
              <NoDataComponent
                title={
                  tabSelect === "accepted"
                    ? "No accepted leaves found."
                    : tabSelect === "rejected"
                    ? "No rejected leaves found."
                    : "No pending leaves found."
                }
                imageSrc={NoLeave}
              />
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default Leave;
