import { useState, useEffect } from "react";

import {
  PageTitle,
  ContentHeader,
  CommonFlex,
  CommonImage,
  CommonText,
  CommonGrid,
} from "../../../../styledComponents/common";

import {
  PrimaryButton,
  WhiteButton,
  ColoredButtonWithIcon,
  ButtonIcon,
  RefreshButton,
} from "../../../../styledComponents/buttons";
import { IntegrationNameContainer, WorkflowCard, WorkflowsContainer } from "../../Setup/integrationSetupStyles";

import BackButtonComponent from "../../../../components/BackButtonComponent/BackButtonComponent";

import ToggleButton from "../../../../components/ToggleButton/ToggleButton";

import refreshIcon from "../../../../assets/img/icons/refresh_white.svg";
import asanaIcon from "../../../../assets/img/integrations/asana.svg";
import SettingsIcon from "../../../../assets/img/Sidebar/settings.svg";

import Loader from "react-spinners/PulseLoader";

const AsanaDetails = (props) => {
  const [integration, setIntegration] = useState(null);
  
  const [workflow, setWorkflow] = useState([]);

  const [syncClicked, setSyncClicked] = useState(false);

  const {
    history,
    selectedOrganization,

    isLoading,
    syncIsLoading,
    syncAsanaIntegration,
    deleteAsanaIntegration,
    isActiveAsana,
    isInstalledAsana,
    getIsActiveAsana,
    updateAsanaIntegration,
    asanaInfo,
  } = props;

  useEffect(() => {
    if( selectedOrganization && selectedOrganization.id ){
      const payload = {
        organization_id: selectedOrganization.id,
      };
      getIsActiveAsana(payload);

      const asanaIntegration = {
        id: "asana",
        name: "asana",
        tag: "asana",
        display_name: "Asana",
        description: "Apploye to Asana Integration",
        is_installed: isInstalledAsana,
        icon: asanaIcon,
        frontend_steps: []
      };

      const asanaWorkflow = {
          "id": "asana",
          "name": "Sync projects and issues from Asana",
          "description": "Import your Asana projects and issues to Apploye projects and tasks",
          "tag": "asana",
          "associated_resource": "all",
          "is_enabled": isActiveAsana
        };

      setIntegration(asanaIntegration);
      setWorkflow(asanaWorkflow);
    }
  }, [selectedOrganization, isActiveAsana]);


  const handleUpdateAsana = (isActive) => {
    if ( selectedOrganization && selectedOrganization.id ) {
      const payload = {
        organization_id: selectedOrganization.id,
        is_active: isActive,
      };

      updateAsanaIntegration(payload);
    }
  };

  const handleSettingsClick = () => {
    history.push({
      pathname: `/user/integrations/asana-update-credentials`,
    });
  };

  const syncAsana = () => {
    setSyncClicked(true);
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      syncAsanaIntegration(payload);
    }
  };

  const deleteAsana = () => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      deleteAsanaIntegration(payload);
    }
  };

  useEffect(() => {
    if( !isInstalledAsana ){
      history.push({
        pathname: `/user/integrations/`,
      });
    }
  }, [isInstalledAsana]);

  useEffect(() => {
    if( syncClicked && asanaInfo ){
      history.push({
        pathname: `/user/integrations/asana-sync`,
        syncClicked : syncClicked,
      });
    }
  }, [asanaInfo]);


  return (
    <div className="content">
      <PageTitle>Asana Details</PageTitle>
      <ContentHeader>
        <BackButtonComponent
          subTitle="Back to all integrations"
          onClick={() => history.push("/user/integrations")}
        />
      </ContentHeader>
      <CommonFlex justifyContent="space-between" margin="40px 0 ">
        <IntegrationNameContainer>
          <CommonFlex gap="12px">
            <CommonImage size="40px" src={integration && integration.icon} />
            <CommonText fontWeight="600" fontSize="16px">
              {integration && integration.display_name}
            </CommonText>
          </CommonFlex>
        </IntegrationNameContainer>

        <CommonFlex>
          {/* <RefreshButton
            isLoading={syncIsLoading}
            onClick={() => {
              syncAsana();
            }}
            padding="0 15px"
          >
            <ButtonIcon src={refreshIcon} alt="" size="22px" />
            Sync Projects & Tasks
          </RefreshButton> */}
          <WhiteButton 
            color="#FE5969;"
            onClick={() => {
              deleteAsana();
            }}
          >
            Delete
          </WhiteButton>

          <CommonImage 
            size="33px" 
            src={SettingsIcon} 
            margin="0 0 0 20px"
            onClick={() => {
              handleSettingsClick();
            }}
            style={{cursor: "pointer"}}
          />
        </CommonFlex>
      </CommonFlex>
      {workflow ? (
        <WorkflowsContainer>
          <WorkflowCard key={workflow.id} enabled={workflow.is_enabled}>
            <CommonFlex justifyContent="space-between">
              <CommonGrid columns="auto">
                <CommonText fontWeight="600">{workflow.name}</CommonText>
                <CommonText $label fontWeight="500">
                  {workflow.description}
                </CommonText>
              </CommonGrid>
              {isLoading && !syncIsLoading ?(
                <Loader
                  size={10}
                  color="#c2cce1"
                />
              ): (
                <CommonFlex gap="12px">
                  <ToggleButton
                    active={isActiveAsana}
                    handleClick={() => {
                      if (!isActiveAsana) {
                        handleUpdateAsana(true);
                      }else{
                        handleUpdateAsana(false);
                      }
                    }}
                  />
                  <CommonText $label fontWeight="500">
                    {isActiveAsana ? "ON" : "OFF"}
                  </CommonText>
                </CommonFlex>
              )}
            </CommonFlex>
          </WorkflowCard>
        </WorkflowsContainer>
      ) : null}

    </div>
  );
};

export default AsanaDetails;
