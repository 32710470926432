import { CommonFlex, PageTitle } from "../../../styledComponents/common";
import {
  ButtonIcon,
  ColoredButtonWithIcon,
  PrimaryButton,
} from "styledComponents/buttons";
import plusIcon from "../../../assets/img/icons/plus_white.svg";
import NoDataComponent from "components/NoDataComponent/NoDataComponent";
import { useEffect, useState } from "react";
import CustomReportListTable from "./CustomReportListTable";
import { Container } from "styledComponents/createProject";
import ComponentCircleLoader from "components/Loaders/ComponentCircleLoader";

const CustomReport = ({
  history,
  organization,
  customReportList,
  getCustomReportList,
  deleteCustomReports,
  isLoading,
  isDeleteLoading,
}) => {
  const [selectedReports, setSelectedReports] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("user_id") && organization?.id)
      getCustomReportList({
        organization_id: organization.id,
        user_id: localStorage.getItem("user_id"),
      });
  }, [organization]);
  return (
    <div className="content">
      <CommonFlex justifyContent="space-between">
        <PageTitle>Custom Report</PageTitle>

        <ColoredButtonWithIcon
          fontSize="14px"
          style={{ marginTop: `auto` }}
          onClick={() => {
            history?.push("/user/reports/create-custom-report");
          }}
        >
          <ButtonIcon src={plusIcon} alt="" />
          Create Report
        </ColoredButtonWithIcon>
      </CommonFlex>
      <Container
        style={{
          padding: `0px`,
          marginTop: `20px`,
          background: `#FCFDFE`,
          overflow: "hidden",
        }}
      >
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <>
            {customReportList?.length > 0 ? (
              <CustomReportListTable
                data={customReportList}
                selectedReports={selectedReports}
                setSelectedReports={setSelectedReports}
                deleteCustomReports={deleteCustomReports}
                organization={organization}
                history={history}
                isDeleteLoading={isDeleteLoading}
              />
            ) : (
              <NoDataComponent title="No custom reports found." />
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default CustomReport;
