import ClearFilterButton from "./clearFilterButton";
import {
  SearchWithClearButtonContainer,
  SearchWithClearButtonInput,
} from "./searchStyles";

const InputWithClearButton = ({
  searchTerm,
  onChange,
  placeHolder,
  clearFunction,
  maxwidth,
  backgroundImage,
  width,
}) => {
  return (
    <SearchWithClearButtonContainer width={width} maxwidth={maxwidth}>
      <SearchWithClearButtonInput
        backgroundImage={backgroundImage}
        type="text"
        value={searchTerm}
        placeholder={placeHolder}
        onChange={(e) => onChange(e)}
      />
      {searchTerm && (
        <div
          style={{
            position: "absolute",
            right: "12px",
            top: "30%",
            zIndex: "1",
          }}
        >
          <ClearFilterButton onClick={() => clearFunction()} />
        </div>
      )}
    </SearchWithClearButtonContainer>
  );
};

export default InputWithClearButton;
